<div class="m-2">
  <div class="flex justify-content-center align-content-center gap-3">
    <div *ngIf="prefix && prefix.length > 0" class="w-2 ">
      <p class="text-right align-content-center vertical-align-middle">{{prefix}}</p>
    </div>
    <div class="flex justify-content-start align-content-center gap-1 md:gap-2 lg:gap-2">
        <input
          *ngFor="let input of inputs; let i = index"
          type="text" #inputElements
          [id]="i"
          [formControl]="input"
          (input)="handleInput($event, i)"
          (focus)="handleFocus($event)"
          (keydown)="handleKeyDown($event, i)"
          (paste)="handlePaste($event, i)"
          [size]="1"
          autocomplete="off"
          ngDefaultControl pInputText style="text-align: center; max-width: 5rem; padding: 10px 0;"/>
    </div>
  </div>
</div>
