import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { ControlValueAccessor } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-multiple-inputs-input',
  templateUrl: './multiple-inputs-input.component.html',
  styleUrls: ['./multiple-inputs-input.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, InputTextModule, ReactiveFormsModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleInputsInputComponent),
      multi: true
    }
  ]
})
export class MultipleInputsInputComponent implements ControlValueAccessor, OnInit {
  @Input() label!: string;
  @Input() prefix!: string;
  @Input() size!: number;
  form: UntypedFormGroup = this.fb.group({}) as UntypedFormGroup;
  value: any;
  @ViewChildren('inputElements') inputElements!: QueryList<ElementRef<HTMLInputElement>>;

  onChange = (input: string) => input;
  onTouched = () => { };

  @Output() onComplete = new EventEmitter<string>();

  constructor(private fb: FormBuilder, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      inputs: this.fb.array(Array(this.size).fill('').map(() => this.fb.control('')))
    });
  }

  writeValue(value: any): void {
    if (value) {
      this.form.patchValue({ inputs: value });
    }

    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  get inputs() {
    return (this.form.controls['inputs'] as FormArray).controls as FormControl[];
  }

  updateValue() {
    let res = this.inputs.map(input => input.value).join('')
    this.onChange(res);
    console.log('emit', res, res.length, this.size);
    if (res.length === this.size) {
      this.onComplete.emit(res);
    }
  }

  handleInput(event: any, index: number) {
    this.inputs[index].setValue(event && event.target && event.target.value.length > 0 ? event.target.value[0] : '');
    console.log(this.inputs[index].value, index)
    this.updateValue();
    if (event.target.value !== '') this.focusInput(index + 1);
  }

  focusInput(index: number) {
    this.cdRef.detectChanges();
    if (index < this.inputs.length) {
      let el = this.inputElements.get(index)?.nativeElement
      el?.focus();
    }
  }

  handleFocus(e: FocusEvent) {
    (e.target as HTMLInputElement).select();
  }

  handleKeyDown(e: KeyboardEvent, idx: number) {
    console.log('idx', idx);
    if (e.key === 'Backspace' || e.key === 'Delete') {
      if (idx > 0 && this.inputs[idx].value === '') {
        e.preventDefault();
        this.focusInput(idx - 1);
      }
    }
  }

  handlePaste(e: ClipboardEvent, idx: number) {
    e.preventDefault();

    if (idx !== 0) {
      // If the target input is not the first one - ignore
      return;
    }

    const pasteData = e.clipboardData?.getData('text');
    if (!pasteData) {
      return;
    }

    for (let i = 0; i < this.size; i++) {
      this.inputs[i].setValue(pasteData[i]);
    }

    this.updateValue();
    this.focusInput(pasteData.length);

  }

}
