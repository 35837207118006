import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import IFormLOPD from 'src/models/IFormLOPD';
import RespGeneric from 'src/models/RespGeneric';

const BACK_URL = environment.api;

@Injectable({
  providedIn: 'root'
})

export class RegistroService {

  constructor(private http: HttpClient) { }

  addRegistro(data: IFormLOPD) {
    return this.http.post<RespGeneric>(`${BACK_URL}/register`, data)
  }

  checkCodigo(data: IFormLOPD) {
    return this.http.post<RespGeneric>(`${BACK_URL}/register/check`, data)
  }

  resendCodigo(data: IFormLOPD) {
    return this.http.post<RespGeneric>(`${BACK_URL}/register/resend`, data)
  }

  getProvincias() {
    return this.http.get<RespGeneric>(`${BACK_URL}/provincias`)
  }

  getMunicipios(provincia: number) {
    return this.http.get<RespGeneric>(`${BACK_URL}/provincias/${provincia}`)
  }

  getOficinas(municipio: string) {
    return this.http.get<RespGeneric>(`${BACK_URL}/provincias/oficinas/${municipio}`)
  }

  getComerciales(oficina: string) {
    return this.http.get<RespGeneric>(`${BACK_URL}/provincias/comerciales/${oficina}`)
  }
}
