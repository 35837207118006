<div [formGroup]="form">
    <div *ngFor="let field of fields">
        <div class="flex flex-column m-3" *ngIf="field.type == 'text'">
            <label class="text-sm mb-0" [htmlFor]="'df'+field.field">{{field.label}} {{(field.required ? '*' : '')}}</label>
            <input  (change)="onChange()" class="surface-300" [pattern]="field.pattern != undefined ? field.pattern : ''" pInputText [id]="'df'+field.field" [placeholder]="(field.placeholder || field.label)" [formControlName]="field.field" />
        </div>
        <div class="flex flex-column m-3" *ngIf="field.type == 'number'">
            <label class="text-sm mb-0" [htmlFor]="'df'+field.field">{{field.label}} {{(field.required ? '*' : '')}}</label>
            <input (change)="onChange()" class="surface-300" pattern="\d*" pInputText [id]="'df'+field.field" [placeholder]="field.label" [formControlName]="field.field" />
        </div>
        <div class="flex flex-column m-3" *ngIf="field.type == 'phone'">
            <label class="text-sm mb-0" [htmlFor]="'df'+field.field">{{field.label}} {{(field.required ? '*' : '')}}</label>
            <input (change)="onChange()" class="surface-300" pattern="^\+?(?:[0-9]\s?){8,12}[0-9]$" pInputText [id]="'df'+field.field" [placeholder]="field.label" [formControlName]="field.field" />
        </div>
        <div class="flex flex-column m-3 mb-0 mt-5" *ngIf="field.type == 'separator'">
          <p class="m-0 font-bold">{{field.label}}</p>
        </div>
        <div class="flex flex-column m-3" *ngIf="field.type == 'select'">
            <label class="text-sm mb-0" [htmlFor]="'df'+field.field">{{field.label}} {{(field.required ? '*' : '')}}</label>
            <div class="flex align-items-center gap-1 mt-2 ml-2 surface-300 p-2 border-round" [id]="'df'+field.field" *ngFor="let item of field.options">
                <p-radioButton [value]="item.value" [formControlName]="field.field" [inputId]="item.id"></p-radioButton>
                <label [for]="item.id">
                    <p class="text-md m-0">
                        {{item.value}}
                    </p>
                    <p class="text-sm m-0">
                        {{item.description}}
                    </p>
                </label>
            </div>
        </div>
        <div class="flex flex-column m-3" *ngIf="field.type == 'dropdown'">
            <label class="text-sm mb-0" [htmlFor]="'df'+field.field">{{field.label}} {{(field.required ? '*' : '')}}</label>
            <p-dropdown styleClass="flex align-items-center  surface-300 border-round" [autoDisplayFirst]="false" [placeholder]="field.label" (onChange)="field.callback($event)" [id]="'df'+field.field" [formControlName]="field.field" [options]="field.options || []" [disabled]="field.options == null || field.options.length == 0" optionLabel="nombre" optionValue="id"></p-dropdown>
        </div>
        <div class="flex flex-column m-3" *ngIf="field.type == 'multiple-input'">
            <label class="" [htmlFor]="'df'+field.label">{{field.label}}</label>
            <app-multiple-inputs-input [id]="'df'+field.field" class="surface-300 border-round"  [label]="field.label" [formControlName]="field.field" [size]="field.config.size" [prefix]="field.config.prefix"></app-multiple-inputs-input>
        </div>
        <app-selector-oficina *ngIf="field.type == 'selector-oficina'" [formControlName]="field.field" [label]="field.label"></app-selector-oficina>
    </div>
    <div *ngIf="!formDone" class="flex flex-column justify-content-center align-items-center mt-3 gap-2">
        <ngx-recaptcha2 #captchaElem [siteKey]="sitekey" formControlName="recaptcha"> </ngx-recaptcha2>
    </div>
</div>
<div *ngIf="!formDone" class="flex flex-column justify-content-center align-items-center mt-3 gap-2">
    <p-button (click)="onSubmit()" [loading]="loading" [disabled]="form.invalid" styleClass="p-button-danger green-btn" label="Enviar código" ></p-button>
</div>
