import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReCaptcha2Component } from 'ngx-captcha';
import { IField } from 'src/models/Field';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})

export class DynamicFormComponent implements OnInit {
  @Input() fields!: IField[];
  @Input() formDone!: boolean;
  @Input() loading: boolean = false;
  @Output('onSubmit') onSubmitEvent = new EventEmitter<any>();
  @ViewChild('captchaElem') captchaElem!: ReCaptcha2Component;
  form!: FormGroup;
  form_data: any = {};
  sitekey: string = '6LdQqFklAAAAAJj6W08EJyNYazVn7W5diFGwWRWi';

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    let form_items: any = {}
    for (let item of this.fields) {
      if (item.type == "separator") continue;
      form_items[item.field] = ['', item.required ? [Validators.required] : []]
    }

    form_items["recaptcha"] = ['', [Validators.required]];
    this.form = this.fb.group(form_items);
  }

  onChange(): void {
    if (this.form.value['recaptcha'] != undefined) this.captchaElem.resetCaptcha();
  }

  onSubmit(): void {
    if (this.form.invalid) return;
    this.form_data = this.form.value;
    this.onSubmitEvent.emit(this.form_data);
  }
}
