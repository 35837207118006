<p-toast></p-toast>
<div class="grid mt-5 justify-content-center">
    <div class="col-10 md:col-8 lg:col-4 bg-white border-round p-3 fadein animation-duration-200">
        <div class="flex flex-column align-items-center justify-content-center">
            <div class="grid flex align-items-center justify-content-center">
                <img class="col" src="../../assets/Grupo-TOPdigital.png" height="100rem" alt="">
                <img class="col" src="../../assets/logo_vodafone.png" height="100rem" alt="">
            </div>
            <h3 class="text-center">Solicitud de información sobre el nuevo contrato de comercializacion de los productos de Vodafone y Lowi</h3>
            <a target="_blank" class="text-600" href="https://grupotopdigital.es/presentacion/politica_privacidad-grupotopdigital.pdf">Acceder a la Ley de Protección de Datos</a>
        </div>
        <app-dynamic-form *ngIf="!waiting" [fields]="fields" [formDone]="hasCodigo" [loading]="loading" (onSubmit)="getCodigo($event)"></app-dynamic-form>
        <div *ngIf="hasCodigo" class="grid justify-content-center">
            <div class="flex flex-column gap-2 col-10 md:col-8 lg:col-6">
                <label htmlFor="username">Código</label>
                <input class="surface-300" pInputText id="username" aria-describedby="username-help" placeholder="Inserte el código recibido por SMS" [(ngModel)]="codigo" />
                <small (click)="sendCodigo()"  class="text-center text-green-600 underline cursor-pointer" id="username-help">Pulse aqui para reenviar el código</small>
            </div>
        </div>
            <div *ngIf="hasCodigo" class="flex flex-column justify-content-center align-items-center mt-3 gap-2">
            <p-button (click)="checkCodigo()" [loading]="loading" styleClass="p-button-danger green-btn" label="Comprobar código" ></p-button>
        </div>
    </div>
</div>

