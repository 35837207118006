import { Component, OnInit, inject } from '@angular/core';
import { IField } from 'src/models/Field';
import { RegistroService } from '../../registro.service';
import { MessageService } from 'primeng/api';
import IFormLOPD from 'src/models/IFormLOPD';

@Component({
  selector: 'app-vdf-form',
  templateUrl: './vdf-form.component.html',
  styleUrls: ['./vdf-form.component.scss'],
  providers: [MessageService]
})
export class VdfFormComponent implements OnInit {

  codigo: any;
  loading: boolean = false;
  waiting: boolean = false;
  hasCodigo: any;
  provincias = [];
  municipios = [];

  fields!: IField[];
  registro: IFormLOPD = {} as IFormLOPD;

  constructor (private registroService: RegistroService, private toast: MessageService) {}

  ngOnInit(): void {
    this.waiting = true;
    this.registroService.getProvincias().subscribe(r => {
      this.provincias = r.data;
      this.fields = [
        {label: 'Nombre y apellidos', field: 'nombre', required: true, type:'text', options:null, callback:() => {} },
        {label: 'DNI', field: 'dni', required: false, type:'text', options:null, callback:() => {}, pattern: '[A-Z0-9]{6,12}' },
        {label: 'Razón Social', field: 'razon_social', required: false, type:'text', options:null, callback:() => {} },
        {label: 'Teléfono', field: 'telefono', required: true, type:'phone', options:null, callback:() => {} },
        {label: 'Email', field: 'email', required: true, type:'text', options:null, callback:() => {}, pattern: '[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}' },
        {label: 'Provincia', field: 'provincia', required: true, type:'dropdown', options:this.provincias, callback:(event) => this.onChangeProvincia(event) },
        {label: 'Municipio', field: 'municipio', required: true, type:'dropdown', options:this.municipios, callback:() => {} },
        {label: 'Dirección', field: 'direccion', required: true, type:'text', options:null, callback:() => {} },
        {label: 'Código postal', field: 'cp', required: true, type:'number', options:null, callback:() => {} },
      ]

      if (window.location.href.includes('colaborador')) {
        this.fields.splice(3, 0,{label: 'CIF/NIF', field: 'nif', required: false, type:'text', options:null, callback:() => {} })
        this.fields.splice(4, 0,{label: 'Asociación, Federación, Grupo, ...', field: 'asociacion', required: false, type:'text', options:null, callback:() => {} })
      }

      this.waiting = false;
    });
  }

  onChangeProvincia(event:any):void {
    this.registroService.getMunicipios(+event.value).subscribe(r => {
      console.log(r);
      this.municipios = r.cod == 200 ? r.data : []
      let field_municipios = this.fields.find(el => el.field == 'municipio');
      if(field_municipios != undefined) field_municipios.options = this.municipios;
    });
  }

  async delay(ms: number): Promise<void> {
    return new Promise(res => setTimeout(res, ms));
  }

  getCodigo(data:any) {
    console.log(data)
    this.registro = data as IFormLOPD;
    this.registro.solucion  = window.location.href.includes('colaborador') ? 'Vodafone Colaborador' : 'Vodafone Cliente'
    this.loading = true;
    this.registroService.addRegistro(this.registro).subscribe(async r => {
      this.loading = false;
      if (r.cod == 200) {
        let found = r.data as IFormLOPD;
        if (found.aceptado == 1) {
          this.toast.add({ severity: 'success', summary: 'LOPD aceptada', detail: 'Ya ha aceptado la LOPD previamente' })
          await this.delay(4000);
          window.location.href = 'https://topdigital.com/info/10-inscripcion-correcta';
        } else {
          this.hasCodigo = true;
          this.toast.add({ severity: 'success', summary: 'SMS enviado', detail: 'Compruebe su teléfono' })
        }
      } else {
        this.toast.add({ severity: 'error', summary: 'Error', detail: r.message })
      }
    });
  }

  async checkCodigo(): Promise<void> {
    this.registro.codigo = this.codigo;
    this.loading = true;
    this.registroService.checkCodigo(this.registro).subscribe(async r => {
      this.loading = false;
      if (r.cod == 200) {
        this.toast.add({ severity: 'success', summary: 'Código correcto', detail: 'LOPD aceptada' })
        await this.delay(4000);
        window.location.href = 'https://topdigital.com/info/10-inscripcion-correcta';
      } else {
        this.toast.add({ severity: 'error', summary: 'Error', detail: r.message })
      }
    })
  }

  sendCodigo(): void {
    this.registroService.resendCodigo(this.registro).subscribe(r => {
      if (r.cod == 200) {
        this.toast.add({ severity: 'success', summary: 'SMS enviado', detail: 'Compruebe su teléfono' })
      } else {
        this.toast.add({ severity: 'error', summary: 'Error', detail: r.message })
      }
    })
  }

}
