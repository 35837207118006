import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { KwSolutionsFormComponent } from './pages/kwsolutions-form/kwsolutions-form.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { VdfFormComponent } from './pages/vdf-form/vdf-form.component';

import { NgxCaptchaModule } from 'ngx-captcha';

// PRIMENG
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { AmigosFormComponent } from './pages/amigos-form/amigos-form.component';
import { MultipleInputsInputComponent } from './components/multiple-inputs-input/multiple-inputs-input.component';
import { SelectorOficinaComponent } from './components/selector-oficina/selector-oficina.component';

@NgModule({
  declarations: [
    AppComponent,
    KwSolutionsFormComponent,
    DynamicFormComponent,
    VdfFormComponent,
    AmigosFormComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    NgxCaptchaModule,
    ToastModule,
    InputNumberModule,
    RadioButtonModule,
    DropdownModule,
    MultipleInputsInputComponent,
    SelectorOficinaComponent
  ],
  providers: [MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
