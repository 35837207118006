import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, CUSTOM_ELEMENTS_SCHEMA, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MultipleInputsInputComponent } from '../multiple-inputs-input/multiple-inputs-input.component';

@Component({
  selector: 'app-selector-oficina',
  templateUrl: './selector-oficina.component.html',
  styleUrls: ['./selector-oficina.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, MultipleInputsInputComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectorOficinaComponent),
      multi: true,
    },
  ],
})
export class SelectorOficinaComponent implements ControlValueAccessor {
  @Input() label: string = '';
  value: any;
  disabled: boolean = false;
  oficina: string = '';

  onChange: any = (input: any) => input;
  onTouched: any = () => { };

  constructor(private http: HttpClient) { }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChangeOficina(event: any) {
    this.value = event;
    this.onChange(event);
    this.http.get(`${environment.api}/provincias/oficina/${event}`).subscribe((res: any) => {
      console.log(res);
      if (res.cod == 200) this.oficina = res.data.oficina;
    })
  }


}
