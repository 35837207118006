import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { RegistroService } from 'src/app/registro.service';
import { IField } from 'src/models/Field';
import IFormLOPD from 'src/models/IFormLOPD';

@Component({
  selector: 'app-amigos-form',
  templateUrl: './amigos-form.component.html',
  styleUrls: ['./amigos-form.component.scss']
})
export class AmigosFormComponent {
  registro: IFormLOPD = {} as IFormLOPD;
  hasCodigo: boolean = false;
  codigo: string = '';
  loading: boolean = false;

  provincias: any[] = [];
  municipios: any[] = [];
  oficinas: any[] = [];
  comerciales: any[] = [];

  defaultOficina = { id: '0890', nombre: '0890 - BANCA DIGITAL' };

  soluciones: any[] = [
    { id: "", value: "Telefonía y electrónica", description: "" },
    { id: "", value: "Electrodomésticos", description: "" },
    { id: "", value: "Muebles del hogar", description: "" },
    { id: "", value: "Cocinas", description: "" },
    { id: "", value: "Kit Digital", description: "" },
    { id: "", value: "Eficiencia Energética", description: "" },
  ]

  fields!: IField[]
  constructor(private registroService: RegistroService, private toast: MessageService) { }
  waiting: boolean = true;

  ngOnInit(): void {
    this.waiting = true;
    this.registroService.getProvincias().subscribe((r: any) => {
      this.provincias = r.data;
      this.fields = [
        { label: '¿En qué solución estás interesado?', field: 'solucion', required: true, type: 'select', options: this.soluciones, callback: () => { } },
        { label: 'Información del usuario', field: 'info', required: true, type: 'separator', options: null, callback: () => { } },
        { label: 'Nombre y apellidos', field: 'nombre', required: true, type: 'text', options: null, callback: () => { }, placeholder: 'Nombre y apellidos' },
        { label: 'DNI', field: 'dni', required: true, type: 'text', options: null, callback: () => { }, pattern: '[A-Z0-9]{6,12}' },
        { label: 'Denominación Social', field: 'razon_social', required: false, type: 'text', options: null, callback: () => { } },
        { label: 'Teléfono', field: 'telefono', required: true, type: 'phone', options: null, callback: () => { } },
        { label: 'Email', field: 'email', required: true, type: 'text', options: null, callback: () => { }, pattern: '[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}' },
        // {label: 'Provincia', field: 'provincia', required: true, type:'dropdown', options:this.provincias, callback:(event) => this.onChangeProvincia(event) },
        // {label: 'Municipio', field: 'municipio', required: true, type:'dropdown', options:this.municipios, callback:(event) => this.onChangeMunicipio(event) },
        // {label: 'Dirección', field: 'direccion', required: true, type:'text', options:null, callback:() => {} },
        // {label: 'Código postal', field: 'cp', required: true, type:'number', options:null, callback:() => {} },
        // {label: 'Oficina', field: 'asociacion', required: true, type:'dropdown', options: this.oficinas, callback:(event) => this.onChangeOficina(event) },
      ]
      this.waiting = false;

    });
  }

  getCodigo(data: any) {
    console.log(data)
    this.registro = data as IFormLOPD;
    this.registro.solucion = `Amigos - ${this.registro.solucion}`;
    this.loading = true;
    this.registroService.addRegistro(this.registro).subscribe(async r => {
      this.loading = false;
      if (r.cod == 200) {
        let found = r.data as IFormLOPD;
        if (found.aceptado == 1) {
          this.toast.add({ severity: 'success', summary: 'LOPD aceptada', detail: 'Ya ha aceptado la LOPD previamente' })
          await this.delay(4000);
          window.location.href = 'https://grupotopdigital.es/amigos/';
        } else {
          this.hasCodigo = true;
          this.toast.add({ severity: 'success', summary: 'SMS enviado', detail: 'Compruebe su teléfono' })
        }
      } else {
        this.toast.add({ severity: 'error', summary: 'Error', detail: r.message })
      }
    });
  }

  async delay(ms: number): Promise<void> {
    return new Promise(res => setTimeout(res, ms));
  }

  onChangeProvincia(event: any): void {
    this.registroService.getMunicipios(+event.value).subscribe(r => {
      console.log(r);
      this.municipios = r.cod == 200 ? r.data : []
      let field_municipios = this.fields.find(el => el.field == 'municipio');
      if (field_municipios != undefined) field_municipios.options = this.municipios;
    });
  }

  onChangeMunicipio(event: any): void {
    let municipio: any = this.municipios.find((el: any) => el.id == event.value);
    if (municipio == undefined) return;
    console.log(municipio);
    this.registroService.getOficinas(municipio.nombre).subscribe(r => {
      this.oficinas = r.cod == 200 ? r.data : []
      this.oficinas.unshift(this.defaultOficina);
      let field_oficinas = this.fields.find(el => el.field == 'asociacion');
      if (field_oficinas != undefined) field_oficinas.options = this.oficinas;
    });
  }

  async checkCodigo(): Promise<void> {
    this.registro.codigo = this.codigo;
    this.loading = true;
    this.registroService.checkCodigo(this.registro).subscribe(async r => {
      this.loading = false;
      if (r.cod == 200) {
        this.toast.add({ severity: 'success', summary: 'Código correcto', detail: 'LOPD aceptada' })
        await this.delay(4000);
        window.location.href = 'https://grupotopdigital.es/amigos/';
      } else {
        this.toast.add({ severity: 'error', summary: 'Error', detail: r.message })
      }
    })
  }

  sendCodigo(): void {
    this.registroService.resendCodigo(this.registro).subscribe(r => {
      if (r.cod == 200) {
        this.toast.add({ severity: 'success', summary: 'SMS enviado', detail: 'Compruebe su teléfono' })
      } else {
        this.toast.add({ severity: 'error', summary: 'Error', detail: r.message })
      }
    })
  }

}
