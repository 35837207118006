import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AmigosFormComponent } from './pages/amigos-form/amigos-form.component';
import { KwSolutionsFormComponent } from './pages/kwsolutions-form/kwsolutions-form.component';
import { VdfFormComponent } from './pages/vdf-form/vdf-form.component';

const routes: Routes = environment.form != 'amigos'
  ? [
    { path: 'kwsolutions', component: KwSolutionsFormComponent },
    { path: '', component: KwSolutionsFormComponent },
    { path: 'cliente-vodafone', component: VdfFormComponent },
    { path: 'colaborador-vodafone', component: VdfFormComponent },
  ]
  : [
    { path: '', component: AmigosFormComponent },
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
