<p-toast></p-toast>
<div class="grid mt-5 justify-content-center">
    <div class="col-10 md:col-8 lg:col-4 bg-white border-round p-3 fadein animation-duration-200">
        <div class="flex flex-column align-items-center justify-content-center">
            <div class="grid flex align-items-center justify-content-center">
                <img class="col" src="../../assets/Grupo-TOPdigital.png" height="100rem" alt="">
                <img class="col" src="../../assets/log_caja_rural.png" height="90rem" alt="">
            </div>
            <h3 class="text-center">Completa el siguiente formulario para recibir la Ley de Protección de Datos</h3>
            <a target="_blank" class="text-green-600" href="https://grupotopdigital.es/presentacion/politica_privacidad-grupotopdigital.pdf">Acceder a la Ley de Protección de Datos</a>
        </div>
        <app-dynamic-form *ngIf="!waiting" [fields]="fields" [formDone]="hasCodigo" [loading]="loading" (onSubmit)="getCodigo($event)"></app-dynamic-form>
        <div *ngIf="hasCodigo" class="grid justify-content-center">
            <div class="flex flex-column gap-2">
                <label htmlFor="codigo">Código</label>
                <div class="flex justify-content-center align-content-center flex-column">
                    <small class="text-center" id="codigo-help">Introduzca el código que le hemos enviado</small>
                    <app-multiple-inputs-input id="codigo" [size]="6" class="surface-300" pInputText aria-describedby="codigo-help" [(ngModel)]="codigo" />
                </div>
                <small (click)="sendCodigo()"  class="text-center text-green-600 underline cursor-pointer" id="codigo-help">Pulse aqui para reenviar el código</small>
            </div>
        </div>
        <div *ngIf="hasCodigo" class="flex flex-column justify-content-center align-items-center mt-3 gap-2">
            <p-button (click)="checkCodigo()" [loading]="loading" styleClass="p-button-danger green-btn" label="Comprobar código" ></p-button>
        </div>
    </div>
</div>
